<template>
  <div class="m_page_contianer_for_main">
    <div id="m_main_logo_content">
      <img id="m_main_logo" src="@/assets/logo.png" />
    </div>
    <Splide :options="{ rewind: true }" id="m_main_splide">
      <SplideSlide v-for="(url, index) in data.list" :key="index">
        <img id="m_main_splide_img" :src="url" />
      </SplideSlide>
    </Splide>
    <div id="m_main_des">
      <div id="m_main_des_content">
        <img id="m_main_des_content_icon" src="@/assets/doubleQuotes.png" />
        <div
          v-for="(text, index) in data.texts"
          :key="'text' + index"
          id="m_main_des_content_text"
        >
          {{ text }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Options, Vue } from "vue-class-component";
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import { kMainPageContentInfo } from "@/config/main";

@Options({
  components: { Splide, SplideSlide },
  data() {
    return {
      data: kMainPageContentInfo.data,
    };
  },
})
export default class Home extends Vue {}
</script>

<style scoped lang="scss">
@import url("../../../less/mobile/page.less");

#m_main_logo_content {
  width: 100%;
  height: 25vh;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

#m_main_logo {
  width: calc(192 / 1080 * 100vw);
  height: calc(192 / 1080 * 100vw);
  object-fit: contain;
}

#m_main_splide {
  width: 100%;
  height: calc(608 / 1080 * 100vw);
}

#m_main_splide_img {
  width: 100%;
  height: calc(608 / 1080 * 100vw);
}

#m_main_des {
  width: 100%;
  height: 25vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#m_main_des_content {
  width: calc(420 / 1080 * 100vw);
  display: flex;
  flex-direction: column;
}

#m_main_des_content_icon {
  width: calc(28 / 1080 * 100vw);
  height: calc(18 / 1080 * 100vw);
  margin-bottom: calc(16 / 1080 * 100vw);
}

#m_main_des_content_text {
  font-size: calc(40 / 1080 * 100vw);
  font-weight: bold;
  color: #333333;
  text-align: center;
}
</style>
export const kMainPageContentInfo = {
    data: {
        list: [
            "https://guinong-dev-1305192904.cos.ap-shanghai.myqcloud.com/wanfei-offical/main/banner2.png",
            "https://guinong-dev-1305192904.cos.ap-shanghai.myqcloud.com/wanfei-offical/main/banner1.png",
            "https://guinong-dev-1305192904.cos.ap-shanghai.myqcloud.com/wanfei-offical/main/banner3.png",
        ],
        texts: [
            "植物护肤就是芦荟",
            "芦荟就是万非",
        ],
        texts2: [
            "万非，就是芦荟！",
            "万非，又不只是芦荟！"
        ],
    },
}
